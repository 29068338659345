import React from "react";
import { Link } from "react-router-dom";

function Error({ scrollToSection }) {
  const h2 = {
    borderRight: "2px solid black",
    paddingRight: "10px",
    fontSize: "16px",
  };
  const getUserData = JSON.parse(localStorage.getItem("USERDATA"));

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "100vh", flexDirection: "column" }}
    >
      <div className="text-center d-flex align-items-center justify-content-center ">
        <h2 className="mb-0" style={h2}>
          404
        </h2>
        <p className="mb-0 ms-3">This page could not be found.</p>
      </div>
      <p className="text-center mt-4">
        Go to back{" "}
        <Link to={getUserData ? "/chat_interface" : "/home"}>
          {getUserData ? "Chat Interface" : "Home"}
        </Link>
      </p>
    </div>
  );
}

export default Error;

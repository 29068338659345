import "../assets/css/Cart.css";
import { useParams } from "react-router";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import Diamond from "../assets/images/diamond.png";
import Platinum from "../assets/images/platinum.png";
import Logo from "../assets/images/Saras-logo-colour.png";
import { useState } from "react";
import Cashfree from "./Cashfree";

const calculatePrices = (pricingplan) => {
  const basePrice = pricingplan === "diamond" ? 1000 : 5000;
  const creditConversionRate = pricingplan === "diamond" ? 2 : 1;
  const taxRate = 0.18;

  const subtotal = pricingplan === "diamond" ? 1000 : 5000;
  const tax = subtotal * taxRate;
  const total = subtotal + tax;

  return { basePrice, creditConversionRate, subtotal, tax, total };
};

const CartCheckout = () => {
  const { pricingplan } = useParams();
  const { basePrice, creditConversionRate, subtotal, tax, total } =
    calculatePrices(pricingplan);
  const apiurl = process.env.REACT_APP_API_HOST;
  const Auth = useAuth();
  const { state } = Auth;
  const id = state?.userData?.user?.id;
  const [data, setData] = useState("");
  const [payment, setPayment] = useState(true);
  const Amount = pricingplan === "diamond" ? 500 : 5000;
  const Credits = pricingplan === "diamond" ? 500 : 5000;

  const ClickHandler = async () => {
    try {
      const { data } = await axios.post(`${apiurl}/users/${id}/orders`, {
        amount: total,
        currency: "INR",
        plan: `${pricingplan} plan`,
        credits: Credits,
      });
      setData(data?.paymentSessionId);
      console.log("data", data);
      setPayment(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="d-block mx-auto" style={{ placeContent: "center" }}>
        <img
          src={Logo}
          className="img-fluid d-block mx-auto"
          loading="lazy"
          alt="saras logo"
        />
        <img
          src={pricingplan === "diamond" ? Diamond : Platinum}
          className="img-fluid mb-2 d-block mx-auto pricingplan-img"
          loading="lazy"
          alt="saras logo"
        />
      </div>
      <div className="cart">
        <div className="card-title">
          <h2>
            You are subscribing{" "}
            <span style={{ textTransform: "capitalize" }}> {pricingplan} </span>{" "}
            Plan
          </h2>
          <p className="text-center"> (1 credit = ₹{creditConversionRate}) </p>
        </div>
        <div className="plan">
          <div className="title">
            <p style={{ textTransform: "capitalize" }}> {pricingplan} Plan </p>
            <p>₹{basePrice}.00</p>
          </div>

          <div className="sub-total">
            <p> Subtotal </p>
            <p>₹{subtotal}.00</p>
          </div>

          <div className="tax">
            <p> Tax(18%) </p>
            <p>₹{tax.toFixed(2)}</p>
          </div>
          <div className="total">
            <p> Total </p>
            <p>₹{total.toFixed(2)}</p>
          </div>

          <div className="check">
            {payment ? (
              <button
                type="button"
                onClick={ClickHandler}
                className="btn btn-outline-primary mx-auto d-block"
              >
                Checkout
              </button>
            ) : (
              <Cashfree sessionId={data} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartCheckout;

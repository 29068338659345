import { useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OnlineOfflineToast = () => {
  const offlineToastIdRef = useRef(null);

  useEffect(() => {
    const handleOnline = () => {
      if (offlineToastIdRef.current) {
        toast.dismiss(offlineToastIdRef.current);
      }
      setTimeout(() => {
        toast.success("You're online!", { autoClose: 3000 });
      }, 500);
    };

    const handleOffline = () => {
      offlineToastIdRef.current = toast.error(
        "You're offline. Please check your internet connection.",
        { autoClose: false }
      );
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return <ToastContainer />;
};

export default OnlineOfflineToast;

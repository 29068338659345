import "../assets/css/Cart.css";
import { Container, Row, Col } from "react-bootstrap";
import CartCheckout from "../components/Cartcheckout";
import topWave from "../assets/images/wave-top-left.png";
import bottomWave from "../assets/images/wave-bottom-right.png";

const Cart = () => {
  return (
    <div className="cart-page">
      <div className="top-wave-bg">
        <img src={topWave} loading="lazy" className="img-fluid" alt="wave" />
      </div>
      <Container>
        <div className="row-content">
          <Row>
            <Col md={12}>
              <CartCheckout />
            </Col>
          </Row>
        </div>
      </Container>
      <div className="bottom-wave-bg">
        <img src={bottomWave} loading="lazy" className="img-fluid" alt="wave" />
      </div>
    </div>
  );
};
export default Cart;
